import { SVGProps, useId } from 'react';

export const BrandColorBanxaFull = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 2 }, (_, i) => `${id}-${i}`);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="12"
      viewBox="0 0 75 12"
      fill="none"
      {...props}
    >
      <g clipPath={`url(#${gradientIds})`}>
        <path
          d="M27.0118 11.9879H15.3385C15.1407 11.9894 14.946 11.9384 14.7743 11.8401C14.6026 11.7419 14.4601 11.5999 14.3611 11.4286C14.2621 11.2573 14.2103 11.0629 14.2109 10.8651C14.2116 10.6673 14.2646 10.4731 14.3646 10.3025L19.8831 0.744795C20.0139 0.518335 20.202 0.330295 20.4285 0.199559C20.6551 0.068824 20.912 0 21.1735 0C21.4351 0 21.692 0.068824 21.9185 0.199559C22.145 0.330295 22.3332 0.518335 22.464 0.744795L27.9824 10.3025C28.0825 10.4731 28.1355 10.6673 28.1361 10.8651C28.1368 11.0629 28.0849 11.2573 27.986 11.4286C27.887 11.5999 27.7444 11.7419 27.5727 11.8401C27.4011 11.9384 27.2064 11.9894 27.0086 11.9879H27.0118ZM17.2878 9.74125H25.0657L21.1767 3.0043L17.2878 9.74125Z"
          fill={`url(#${gradientIds[0]})`}
        />
        <path
          d="M73.7643 11.9879H62.0908C61.8933 11.9891 61.6988 11.9379 61.5273 11.8395C61.3558 11.7411 61.2133 11.5991 61.1148 11.4279C61.0158 11.2567 60.9643 11.0623 60.9648 10.8647C60.9658 10.667 61.0188 10.473 61.1188 10.3025L66.6353 0.744795C66.7663 0.518335 66.9543 0.330295 67.1808 0.199559C67.4073 0.068824 67.6643 0 67.9258 0C68.1873 0 68.4443 0.068824 68.6708 0.199559C68.8973 0.330295 69.0854 0.518335 69.2163 0.744795L74.7348 10.3025C74.8348 10.4731 74.8878 10.6673 74.8883 10.8651C74.8893 11.0629 74.8373 11.2573 74.7383 11.4286C74.6393 11.5999 74.4968 11.7419 74.3253 11.8401C74.1533 11.9384 73.9588 11.9894 73.7608 11.9879H73.7643ZM64.0418 9.74125H71.8198L67.9293 3.0043L64.0418 9.74125Z"
          fill="black"
        />
        <path
          d="M42.6121 0.233398H41.1937C40.8851 0.233398 40.7316 0.393306 40.7316 0.713122V8.32157L33.9179 0.410896C33.8726 0.355873 33.8157 0.311463 33.7513 0.280795C33.687 0.250128 33.6166 0.233949 33.5453 0.233398H31.7863C31.4793 0.233398 31.3242 0.393306 31.3242 0.713122V11.5149C31.3242 11.8347 31.4841 11.9947 31.7863 11.9947H33.2047C33.5134 11.9947 33.6669 11.8347 33.6669 11.5149V3.73859L40.666 11.8395C40.7054 11.8912 40.7566 11.9327 40.8153 11.9606C40.874 11.9884 40.9385 12.0017 41.0035 11.9995H42.6025C42.9112 11.9995 43.0663 11.8395 43.0663 11.5197V0.713122C43.0711 0.393306 42.916 0.233398 42.6121 0.233398Z"
          fill="black"
        />
        <path
          d="M58.3085 11.2319L53.807 5.83977L57.747 1.01696C57.815 0.924272 57.8515 0.812137 57.851 0.697142C57.86 0.634397 57.854 0.570477 57.834 0.510407C57.8135 0.450335 57.78 0.395753 57.735 0.350957C57.6905 0.306161 57.6355 0.272373 57.5755 0.252252C57.5155 0.232132 57.4515 0.226228 57.389 0.235005H55.438C55.3665 0.233062 55.295 0.248133 55.2305 0.278985C55.1655 0.309837 55.109 0.355593 55.0655 0.412504L52.299 3.94168L49.5311 0.423697C49.4875 0.361149 49.429 0.310518 49.3608 0.276437C49.2927 0.242355 49.2171 0.225911 49.1409 0.228609H47.1868C47.1241 0.219765 47.0602 0.225636 47.0002 0.245758C46.9402 0.265881 46.8857 0.299701 46.841 0.344541C46.7963 0.389381 46.7627 0.44401 46.7428 0.504102C46.7229 0.564192 46.7172 0.628092 46.7263 0.690742C46.7252 0.805812 46.7617 0.918087 46.8302 1.01056L50.7685 5.84137L46.2833 11.2143C46.2032 11.3015 46.1592 11.4158 46.1602 11.5341C46.1602 11.8412 46.3201 11.9947 46.6399 11.9947H48.6899C48.7658 11.9953 48.8407 11.9779 48.9085 11.944C48.9763 11.9101 49.0351 11.8606 49.0801 11.7996L52.291 7.77307L55.518 11.8364C55.5645 11.8886 55.622 11.93 55.6865 11.9576C55.751 11.9852 55.8205 11.9984 55.8905 11.9963H57.9485C58.011 12.005 58.075 11.9992 58.1345 11.9792C58.1945 11.9591 58.249 11.9254 58.2935 11.8808C58.3385 11.8361 58.372 11.7817 58.392 11.7219C58.412 11.662 58.418 11.5983 58.409 11.5357C58.4145 11.4255 58.3785 11.3172 58.3085 11.2319Z"
          fill="black"
        />
        <path
          d="M10.2577 5.82001C10.7333 5.21981 10.9894 4.47503 10.9836 3.70923C10.99 3.2503 10.9011 2.79503 10.7225 2.37224C10.5438 1.94946 10.2794 1.56835 9.9458 1.25304C9.6209 0.923601 9.2327 0.663371 8.8045 0.488031C8.3763 0.312692 7.91705 0.225878 7.45445 0.232824H0.484034C0.418858 0.223458 0.352396 0.22941 0.289921 0.250206C0.227445 0.271002 0.170676 0.306071 0.124115 0.352631C0.077556 0.399191 0.0424866 0.45596 0.0216904 0.518436C0.000894049 0.580911 -0.0050572 0.647371 0.00430893 0.712551V11.5144C0.00430893 11.8342 0.164217 11.9941 0.484034 11.9941H8.0573C8.5208 12.0033 8.9814 11.9185 9.41125 11.7448C9.8411 11.5711 10.2313 11.3121 10.5583 10.9835C10.8907 10.6689 11.1541 10.2887 11.332 9.86701C11.5098 9.44531 11.5981 8.99126 11.5913 8.53366C11.604 8.00736 11.4893 7.48581 11.2571 7.01336C11.0249 6.54091 10.6821 6.13151 10.2577 5.82001ZM2.34537 2.80735C2.3366 2.7444 2.34255 2.68027 2.36275 2.62001C2.38296 2.55975 2.41686 2.505 2.46181 2.46005C2.50675 2.41511 2.5615 2.38121 2.62176 2.361C2.68202 2.3408 2.74615 2.33485 2.8091 2.34361H7.41605C7.5744 2.34381 7.7307 2.37921 7.8737 2.44724C8.0167 2.51527 8.14275 2.61424 8.2428 2.73699C8.467 3.00643 8.5898 3.3459 8.5898 3.69644C8.5898 4.04698 8.467 4.38644 8.2428 4.65589C8.1441 4.78071 8.01845 4.88163 7.8753 4.95112C7.7322 5.02061 7.5752 5.05691 7.41605 5.05726H2.8091C2.74608 5.06636 2.68182 5.06066 2.6214 5.04056C2.56098 5.02051 2.50608 4.98659 2.46106 4.94157C2.41603 4.89655 2.38213 4.84165 2.36205 4.78123C2.34196 4.72081 2.33626 4.65654 2.34537 4.59352V2.80735ZM8.84565 9.48351C8.74645 9.60756 8.6207 9.70776 8.4776 9.77666C8.3345 9.84561 8.17775 9.88151 8.0189 9.88171H2.8091C2.50047 9.88171 2.34537 9.72181 2.34537 9.40196V7.64296C2.34537 7.32316 2.50528 7.16326 2.8091 7.16326H8.0189C8.17785 7.16386 8.3346 7.20011 8.47765 7.26931C8.6207 7.33846 8.74645 7.43886 8.84565 7.56301C9.0763 7.82871 9.2 8.17071 9.19265 8.52246C9.20275 8.87521 9.07875 9.21861 8.84565 9.48351Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="16.4514"
          y1="13.5614"
          x2="25.9036"
          y2="4.10927"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0073D1" />
          <stop offset="0.06" stopColor="#0082CE" />
          <stop offset="0.22" stopColor="#00A0C9" />
          <stop offset="0.39" stopColor="#00B8C4" />
          <stop offset="0.57" stopColor="#00C9C1" />
          <stop offset="0.76" stopColor="#00D3C0" />
          <stop offset="1" stopColor="#00D6BF" />
        </linearGradient>
        <clipPath id={gradientIds[1]}>
          <rect width="75" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
